@import '../vars.css';

.error {
  color: red;
}

.pill {
  border-radius: 15px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  margin: 0.5rem;
  display: inline-block;
}

.backgroundOk {
  background-color: silver;
}

.backgroundErr {
  background-color: lightcoral;
}

.detail {
  position: relative;
  padding: 15px;
  margin: 5px;
}

.viewDetail {
  composes: detail;
  overflow-x: auto;
}

.editDetail {
  composes: detail;
  overflow-y: visible;
}

.borderOk {
  border: 2px solid silver;
}

.borderErr {
  border: 2px solid lightcoral;
}

.focused {
  box-shadow: var(--box-shadow-standard);
}

.columnName {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--color-defaultText);
}

.reference {
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
}

.editButton:hover i {
  color: rgba(100, 100, 100, 1);
}

.editButton {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none !important;
  font-size: 20px;
}

.editButton:active {
  background: transparent;
}

.editButton i {
  margin: 5px;
  color: rgba(100, 100, 100, 0.4);
}

.indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 10px;
}
