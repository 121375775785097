@import './vars.css';

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

#root {
  overflow: auto;
}

button {
  border: 1px solid #ddd;
  border-radius: 2px;
  background-color: transparent;
  transition: background-color ease-out 0.2s;
  padding: 1px 6px;
  margin: 4px;
  outline: none;
}

button:disabled {
  background-color: #ebebeb;
}

button:enabled:hover,
button:enabled:focus {
  border-color: var(--color-selectionOutline);
}

button:enabled:active {
  background-color: #ddd;
  transition: background-color ease-out 0s;
}

button:enabled.active {
  background-color: var(--color-activeItemBg);
}

button::-moz-focus-inner {
  border: 0; /*removes ugly dotted border inside of button when focused*/
}

i.fa,
i.fas,
i.far {
  color: inherit;
  padding: 0;
}

#table-content {
  outline: none;
}
