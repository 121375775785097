.colMarker {
  --color: 33, 45, 59;
  position: absolute;
  pointer-events: none; /*chrome wouldn't run events on table divs without this*/
}

.colMarkerTarget {
  composes: colMarker;
  background: linear-gradient(
    var(--direction),
    rgba(var(--color), 0) 60%,
    rgba(var(--color), 0.004) 70%,
    rgba(var(--color), 0.02) 80%,
    rgba(var(--color), 0.07) 90%,
    rgba(var(--color), 0.12) 95%,
    rgba(var(--color), 0.2) 100%
  );
  border-color: #e72076;
  box-sizing: border-box;
  border-width: thin;
}

.colMarkerRight {
  --direction: to right;
  border-style: none solid none none;
  composes: colMarkerTarget;
}

.colMarkerLeft {
  --direction: to left;
  border-style: none none none solid;
  composes: colMarkerTarget;
}

.colMarkerFull {
  composes: colMarker;
  background: rgba(var(--color), 0.2);
}
