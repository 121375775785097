.disabled {
  color: gray;
  cursor: default;
}

.field label:after {
  content: ':';
}

.field input,
.field select {
  margin-left: 5px;
}
