@import '../vars.css';

.headerRoot {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.headerContent {
  position: sticky;
  top: -10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  background-color: var(--color-defaultBg);
  border-bottom: 2px solid silver;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
}

.title {
  margin-left: 5px;
  float: left;
  font-size: 22px;
  font-weight: bold;
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.headerId {
  width: min-content;
  background-color: #e0e0e0;
  border-radius: 0.75rem;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
  /*margin: 1rem 1.5rem;*/
}

.button {
  background: transparent;
  border: none !important;
  font-size: 22px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
}

i {
  float: left;
  display: inline-block;
  padding: 3px;
  color: #888888;
}

.button:hover {
  cursor: pointer;
}

.button:hover i {
  color: #555555;
}

.button:disabled {
  background: transparent;
}

.button:disabled i {
  color: #c0c0c0;
}

.editing i {
  color: #00bb00;
}
