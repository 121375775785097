:root {
  --color-grid: #e3ecf2;
  --color-gridBg: #f3f3f3;
  --color-gridBorder: #c0c0c0;
  --color-defaultBg: white;
  --color-defaultText: #212d3b;
  --color-headerBg: #f2f4f6;
  --color-headerText: #212d3b;
  --color-frozen: aliceblue;
  --color-selectionOutline: #e72076;
  --color-selectionBorder: white;
  --color-selectionOverlay: rgba(231, 32, 118, 0.2);
  --color-selectedRow: #fad2e3;
  --color-inputOutline: #3b99fc;
  --color-error: #c0392e;
  --color-tooltip: black;
  --color-conflict: #f2b32a;
  --color-positive: #3a835b;
  --color-negative: #d9364c;
  --color-activeItemBg: #caf9f9;
  --color-panelBorder: black;
  --color-scrollbarTrack: #f1f1f1;
  --color-scrollbarThumb: #888888;
  --color-scrollbarThumbHover: #555555;
  --color-selectedListItem: #3b99fc;
  --color-dimText: #c0c0c0;

  --color-diffOldValueBg: #fdb8c0;
  --color-diffCurrentValueBg: #acf2bd;

  --box-shadow-standard: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16), 0 0.3rem 0.6rem rgba(0, 0, 0, 0.23);

  --font-normal: 12px Eina01;
  --font-mono: 12px Eina01;
  --font-navigation: 12px Courier;
}
