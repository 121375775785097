@import '../vars.css';

.tooltip {
  position: absolute;
  box-sizing: border-box;
  padding: 0.9rem 0.6rem 0.6rem;
  overflow: auto;
  line-height: 1.4;
  font: var(--font-normal);
  border-left-width: 0.3rem;
  border-left-style: solid;
  background-color: var(--color-defaultBg);
  box-shadow: var(--box-shadow-standard);
}

.title {
  display: block;
  margin-bottom: 0.6rem;
}

.content {
  display: block;
}
