@import '../vars.css';

.tooltip {
  margin: 10px;
  line-height: 1.4;
  border-top-width: 0.3rem;
  border-top-style: solid;
}

.title {
  margin-bottom: 0.6rem;
}

.content {
  color: var(--color-defaultText);
  word-break: break-word;
}

.tooltipsContainer {
  height: 100%;
  width: 25%;
  overflow-y: auto;
}
