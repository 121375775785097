@import '../vars.css';

.title {
  margin: 0 0 0.3rem;
}

.body {
  display: block;
}

.oldValue,
.currentValue {
  font-family: monospace;
  display: inline-block;
  border-radius: 0.3rem;
  margin: 0 0.3rem 0.15rem 0;
}

.oldValue {
  background-color: var(--color-diffOldValueBg);
  text-decoration: line-through;
}

.currentValue {
  background-color: var(--color-diffCurrentValueBg);
}
