@import './vars.css';

.entityNameInput {
  border: none;
  border-bottom: 1px solid black;
  margin: 0 10px;
  font: 16px courier;
  outline: none;
}

.entityDescriptionInput {
  border: none;
  border-bottom: 1px solid black;
  margin: 0 10px;
  font: 16px courier;
  outline: none;
}

.headingHighlight {
  font-size: 20px;
  font-style: italic;
}

.headingHighlight:before {
  content: ' ';
}
