.errorText {
  margin-bottom: 5px;
}

.canDrag {
  cursor: grab;
}

.dragging {
  cursor: grabbing;
}

.canClick {
  cursor: pointer;
}
